<template>
    <b-container fluid>
      <b-row>
        <b-col md="12">
          <iq-card>
            <template v-slot:headerTitle>
                <h4 class="card-title">{{$t('elearning_tpm.training_calendar')}}</h4>
            </template>
            <template v-slot:headerAction>
                <router-link to="other-training-calender-list" :class="'btn btn_add_new font-weight-bold'">{{ $t('elearning_tpm.training_calendar') }} {{ $t('globalTrans.list') }}</router-link>
            </template>
            <template v-slot:body>
              <b-overlay :show="loading">
                <b-row>
                  <b-col lg="12" sm="12">
                    <ValidationObserver ref="form"  v-slot="{ handleSubmit }">
                      <b-form  @submit.prevent="handleSubmit(register)" >
                          <b-row>
                            <b-col lg="6" sm="6">
                              <ValidationProvider name="Fiscal Year" rules="required|min_value:1">
                                <b-form-group
                                  class="row"
                                  label-cols-sm="4"
                                  label-for="fiscal_year_id"
                                  slot-scope="{ valid, errors }"
                                  >
                                  <template v-slot:label>
                                      {{$t('elearning_config.fiscal_year')}} <span class="text-danger">*</span>
                                    </template>
                                  <b-form-select
                                    plain
                                    v-model="formData.fiscal_year_id"
                                    :options="fiscalYearList"
                                    id="fiscal_year_id"
                                    :state="errors[0] ? false : (valid ? true : null)"
                                    >
                                    <template v-slot:first>
                                      <b-form-select-option :value="0" >{{$t('globalTrans.select')}}</b-form-select-option>
                                    </template>
                                  </b-form-select>
                                  <div class="invalid-feedback">
                                    {{ errors[0] }}
                                  </div>
                                </b-form-group>
                              </ValidationProvider>
                            </b-col>
                            <!-- <b-col lg="6" sm="6">
                              <ValidationProvider name="Office Type" vid="org_id" rules="required|min_value:1">
                                <b-form-group
                                    class="row"
                                    label-cols-sm="4"
                                    label-for="org_id"
                                    slot-scope="{ valid, errors }"
                                >
                                  <template v-slot:label>
                                    {{$t('globalTrans.organization')}} <span class="text-danger">*</span>
                                  </template>
                                  <b-form-select
                                      plain
                                      v-model="formData.org_id"
                                      :options="orgList"
                                      id="org_id"
                                      :state="errors[0] ? false : (valid ? true : null)"
                                      :disabled="$store.state.Auth.activeRoleId === 1 ? false : true"
                                  >
                                    <template v-slot:first>
                                      <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                                    </template>
                                  </b-form-select>
                                  <div class="invalid-feedback">
                                    {{ errors[0] }}
                                  </div>
                                </b-form-group>
                              </ValidationProvider>
                            </b-col> -->
                            <b-col lg="6" sm="6">
                              <ValidationProvider name="Office Type" vid="office_type_id" rules="required|min_value:1">
                                <b-form-group
                                    class="row"
                                    label-cols-sm="4"
                                    label-for="office_type_id"
                                    slot-scope="{ valid, errors }"
                                >
                                  <template v-slot:label>
                                    {{$t('elearning_config.office_type')}} <span class="text-danger">*</span>
                                  </template>
                                  <b-form-select
                                      plain
                                      v-model="formData.office_type_id"
                                      :options="officeTypeList"
                                      id="office_type_id"
                                      :state="errors[0] ? false : (valid ? true : null)"
                                      :disabled="$store.state.Auth.activeRoleId === 1 ? false : true"
                                  >
                                    <template v-slot:first>
                                      <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                                    </template>
                                  </b-form-select>
                                  <div class="invalid-feedback">
                                    {{ errors[0] }}
                                  </div>
                                </b-form-group>
                              </ValidationProvider>
                            </b-col>
                            <b-col lg="6" sm="6">
                              <ValidationProvider name="Office" vid="office_id" rules="required|min_value:1">
                                <b-form-group
                                    class="row"
                                    label-cols-sm="4"
                                    label-for="office_id"
                                    slot-scope="{ valid, errors }"
                                >
                                  <template v-slot:label>
                                    {{$t('globalTrans.office')}} <span class="text-danger">*</span>
                                  </template>
                                  <b-form-select
                                      plain
                                      v-model="formData.office_id"
                                      :options="officeList"
                                      id="office_id"
                                      :state="errors[0] ? false : (valid ? true : null)"
                                      :disabled="$store.state.Auth.activeRoleId === 1 ? false : true"
                                  >
                                    <template v-slot:first>
                                      <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                                    </template>
                                  </b-form-select>
                                  <div class="invalid-feedback">
                                    {{ errors[0] }}
                                  </div>
                                </b-form-group>
                              </ValidationProvider>
                            </b-col>
                          </b-row>
                          <p style="font-weight:bold; color:gray; text-transform:uppercase"> {{ '' }} </p>
                          <hr style="margin-top:-15px">
                        <div>
                          <b-row v-for="(item,index) in formData.details" :key="index">
                            <b-col lg="4" md="4" sm="4">
                              <ValidationProvider name="Training Quarter" vid="training_quarter_id" rules="required|min_value:1">
                                <b-form-group
                                    class="row"
                                    label-cols-sm="12"
                                    label-for="training_quarter_id"
                                    slot-scope="{ valid, errors }"
                                >
                                <template v-slot:label>
                                    {{ $t('elearning_tpm.training_quarter') }} <span class="text-danger">*</span>
                                  </template>
                                  <b-form-select
                                      plain
                                      id="training_quarter_id"
                                      v-model="item.training_quarter_id"
                                      :options="trainingQuarterSetupList"
                                      :state="errors[0] ? false : (valid ? true : null)"
                                  >
                                  <template v-slot:first>
                                      <b-form-select-option :value="0" >{{$t('globalTrans.select')}}</b-form-select-option>
                                  </template>
                                </b-form-select>
                                <div class="invalid-feedback">
                                    {{ errors[0] }}
                                </div>
                                </b-form-group>
                              </ValidationProvider>
                            </b-col>
                            <b-col lg="4" md="4" sm="4">
                              <ValidationProvider name="Training Type" vid="training_type_id" rules="required|min_value:1">
                                  <b-form-group
                                      class="row"
                                      label-cols-sm="12"
                                      label-for="training_type_id"
                                      slot-scope="{ valid, errors }"
                                  >
                                  <template v-slot:label>
                                    {{ $t('elearning_config.training_type') }} <span class="text-danger">*</span>
                                  </template>
                                  <b-form-select
                                      plain
                                      id="training_type_id"
                                      v-model="item.training_type_id"
                                      :options="trainingTypeList"
                                      :state="errors[0] ? false : (valid ? true : null)"
                                  >
                                  <template v-slot:first>
                                      <b-form-select-option :value="0" >{{$t('globalTrans.select')}}</b-form-select-option>
                                  </template>
                                  </b-form-select>
                                  <div class="invalid-feedback">
                                      {{ errors[0] }}
                                  </div>
                                  </b-form-group>
                              </ValidationProvider>
                            </b-col>
                            <b-col lg="4" md="4" sm="4">
                              <ValidationProvider name="Quantity" vid="quarterly_goals" rules="required">
                                <b-form-group
                                  class="row"
                                  label-cols-sm="12 p-0 m-0"
                                  label-for="quarterly_goals"
                                  slot-scope="{ valid, errors }"
                                >
                                <template v-slot:label>
                                    {{ $t('elearning_tpm.quarterly_goals') }} <span class="text-danger">*</span>
                                  </template>
                                  <b-form-input
                                    type="number"
                                    id="quarterly_goals"
                                    v-model="item.quarterly_goals"
                                    :state="errors[0] ? false : (valid ? true : null)"
                                    @keyup="dateFormation(index, item.quarterly_goals)"
                                    ></b-form-input>
                                  <div class="invalid-feedback">
                                    {{ errors[0] }}
                                  </div>
                                </b-form-group>
                              </ValidationProvider>
                            </b-col>
                            <template v-if="item.quarterly_goals > 0">
                                <b-row v-for="(item2, index2) in item.dates" :key="index2" class="px-3">
                                    <b-col lg="3" md="3" sm="3">
                                        <ValidationProvider name="Start Date" vid="start_date" rules="required">
                                            <b-form-group
                                            class="row"
                                            label-cols-sm="12 p-0 m-0"
                                            label-for="start_date"
                                            slot-scope="{ valid, errors }"
                                            >
                                            <template v-slot:label>
                                                {{ $t('globalTrans.from_date') }} <span class="text-danger">*</span>
                                            </template>
                                            <flat-pickr class="form-control"
                                                v-model="item2.start_date"
                                                :id="`date_from${index}`"
                                                :state="errors[0] ? false : (valid ? true : null)"
                                                >
                                            </flat-pickr>
                                            <div class="invalid-feedback">
                                                {{ errors[0] }}
                                            </div>
                                            </b-form-group>
                                        </ValidationProvider>
                                    </b-col>
                                    <b-col lg="3" md="3" sm="3">
                                        <ValidationProvider name="end_date" vid="end_date" rules="required">
                                            <b-form-group
                                            class="row"
                                            label-cols-sm="12 p-0 m-0"
                                            label-for="end_date"
                                            slot-scope="{ valid, errors }"
                                            >
                                            <template v-slot:label>
                                                {{ $t('globalTrans.to_date') }} <span class="text-danger">*</span>
                                            </template>
                                            <flat-pickr class="form-control"
                                                v-model="item2.end_date"
                                                :id="`date_to${index}`"
                                                :state="errors[0] ? false : (valid ? true : null)"
                                                >
                                            </flat-pickr>
                                            <div class="invalid-feedback">
                                                {{ errors[0] }}
                                            </div>
                                            </b-form-group>
                                        </ValidationProvider>
                                    </b-col>
                                    <b-col lg="3" md="3" sm="3">
                                        <ValidationProvider name="Venue Name (En)" vid="venu_name" rules="required">
                                            <b-form-group
                                            class="row"
                                            label-cols-sm="12 p-0 m-0"
                                            label-for="venu_name"
                                            slot-scope="{ valid, errors }"
                                            >
                                            <template v-slot:label>
                                                {{ $t('elearning_tpm.upazila_venue') }} {{ $t('globalTrans.enn') }} <span class="text-danger">*</span>
                                            </template>
                                            <b-form-input
                                                id="venu_name"
                                                v-model="item2.venu_name"
                                                :state="errors[0] ? false : (valid ? true : null)"
                                                ></b-form-input>
                                            <div class="invalid-feedback">
                                                {{ errors[0] }}
                                            </div>
                                            </b-form-group>
                                        </ValidationProvider>
                                    </b-col>
                                    <b-col lg="3" md="3" sm="3">
                                        <ValidationProvider name="Venue Name (Bn)" vid="venu_name_bn" rules="required">
                                            <b-form-group
                                            class="row"
                                            label-cols-sm="12 p-0 m-0"
                                            label-for="venu_name_bn"
                                            slot-scope="{ valid, errors }"
                                            >
                                            <template v-slot:label>
                                                {{ $t('elearning_tpm.upazila_venue') }} ({{ $t('globalTrans.bangla') }}) <span class="text-danger">*</span>
                                            </template>
                                            <b-form-input
                                                id="venu_name_bn"
                                                v-model="item2.venu_name_bn"
                                                :state="errors[0] ? false : (valid ? true : null)"
                                                ></b-form-input>
                                            <div class="invalid-feedback">
                                                {{ errors[0] }}
                                            </div>
                                            </b-form-group>
                                        </ValidationProvider>
                                    </b-col>
                                </b-row>
                            </template>
                            <b-col lg="12" md="12" sm="12" style="text-align: right">
                                <b-form-group>
                                    <b-button class="btn btn-outline-danger mt-3" v-if="index !== 0" variant=" iq-bg-danger" size="sm" @click.prevent="removeItem(index)"><i class="ri-delete-bin-line m-0"></i></b-button>
                                    <b-button class="btn btn-outline-success mt-3" v-if="index === 0" variant=" iq-bg-success" size="sm" @click="addMore"><i class="ri-add-line m-0"></i></b-button>
                              </b-form-group>
                            </b-col>
                          </b-row>
                        </div>
                        <div class="row">
                          <div class="col-sm-3"></div>
                          <div class="col text-right">
                            <b-button type="submit" variant="primary" class="mr-2">{{ saveBtnName }}</b-button>
                            &nbsp;
                            <!-- <b-button variant="danger" class="mr-1" @click="$bvModal.hide('modal-4')">{{ $t('globalTrans.cancel') }}</b-button> -->
                          </div>
                        </div>
                      </b-form>
                    </ValidationObserver>
                  </b-col>
                </b-row>
              </b-overlay>
            </template>
          </iq-card>
        </b-col>
      </b-row>
    </b-container>
  </template>
  <script>
  import { core } from '../../../../../config/pluginInit'
  import { ValidationObserver, ValidationProvider } from 'vee-validate'
  import RestApi, { trainingElearningServiceBaseUrl } from '@/config/api_config'
  import { otherTrainingCalendarStore, otherTrainingCalendarUpdate, otherTrainingCalendarDetails } from '../../api/routes'
  import flatpickr from 'flatpickr'

  export default {
    name: 'FormLayout',
    props: ['id'],
    components: {
      ValidationObserver,
      ValidationProvider
    },
    created () {
      if (this.$route.query.id) {
        this.calenderData()
      } else {
          this.formData = Object.assign({}, this.formData, {
          fiscal_year_id: this.$store.state.TrainingElearning.currentFiscalYearId
        })
        if (this.$store.state.Auth.activeRoleId !== 1) {
          this.formData.org_id = this.$store.state.Auth.authUser.org_id
          this.formData.office_type_id = this.$store.state.Auth.authUser.office_type_id
          this.formData.office_id = this.$store.state.Auth.authUser.office_id
          this.officeTypeList = this.getOfficeTypeOfficerList(this.$store.state.Auth.authUser.office_type_id)
        } else {
          this.officeTypeList = this.getOfficeTypeList(this.formData.org_id)
        }
      }
      this.officeTypeList = this.getOfficeTypeList(this.formData.org_id)
      this.officeList = this.getOfficeList(this.formData.office_type_id)
      this.trainingTypeList = this.getTypeList(this.formData.org_id)
    },
    mounted () {
      flatpickr('.date-picker', {})
      core.index()
    },
    data () {
      return {
        loading: false,
        saveBtnName: this.$route.query.id ? this.$t('elearning_config.update') : this.$t('globalTrans.save'),
        formData: {
          fiscal_year_id: 0,
          org_id: 16,
          office_type_id: 0,
          office_id: 0,
          details: [
            {
              training_quarter_id: 0,
              training_type_id: 0,
              quarterly_goals: '',
              dates: [
                {
                    start_date: '',
                    end_date: '',
                    venu_name: '',
                    venu_name_bn: ''
                }
              ]
            }
          ]
        },
        categoryList: [],
        officeTypeList: [],
        officeList: [],
        trainingTypeList: [],
        isValid: false
      }
    },
    computed: {
      orgList: function () {
        return this.$store.state.commonObj.organizationProfileList.filter(item => item.status === 0)
      },
      office: function () {
       const officeId = this.$store.state.Auth.authUser.office_id
       return this.$store.state.commonObj.officeList.find(item => item.value === officeId)
      },
      // officeTypeList () {
      //   return this.$store.state.commonObj.officeTypeList.filter(item => item.status === 1 && item.org_id === 16)
      // },
      trainingQuarterSetupList: function () {
        return this.$store.state.TrainingElearning.commonObj.trainingQuarterSetupList.filter(item => item.status === 1)
      },
      fiscalYearList: function () {
        return this.$store.state.commonObj.fiscalYearList.filter(item => item.status === 0)
      }
    },
    watch: {
      'formData.office_type_id': function (newValue) {
        this.officeList = this.getOfficeList(newValue)
      }
    },
    methods: {
      getOfficeTypeOfficerList (officeTypeId) {
        const OfficeTypeList = this.$store.state.commonObj.officeTypeList.filter(item => item.status === 0)
        if (officeTypeId) {
          return OfficeTypeList.filter(item => item.value === officeTypeId)
        }
        return OfficeTypeList
      },
      getOfficeList (officeTypeId) {
        const officeList = this.$store.state.commonObj.officeList.filter(item => item.status === 0)
        if (officeTypeId) {
          return officeList.filter(item => item.office_type_id === officeTypeId)
        }
        return officeList
      },
      getOfficeTypeList (orgId) {
        const OfficeTypeList = this.$store.state.commonObj.officeTypeList.filter(item => item.status === 0)
        if (orgId) {
          return OfficeTypeList.filter(item => item.org_id === orgId)
        }
        return OfficeTypeList
      },
      getTypeList () {
        if (this.formData.org_id) {
          const type = this.$store.state.TrainingElearning.commonObj.trainingTypeList.filter(item => item.status === 1)
            return type.filter(item => item.org_id === parseInt(this.formData.org_id))
        }
      },
      calenderData () {
        const params = Object.assign({}, { id: this.$route.query.id })
        this.loading = true
        this.$store.dispatch('mutateCommonProperties', { loading: true })
        const loadinState = { loading: false, listReload: true }
        RestApi.getData(trainingElearningServiceBaseUrl, otherTrainingCalendarDetails, params).then(response => {
            this.loading = false
            this.$store.dispatch('mutateCommonProperties', loadinState)
              if (response.success) {
                this.formData = response.data
              }
          })
      },
      async register () {
        this.loading = true
        this.$store.dispatch('mutateCommonProperties', { loading: true })
        let result = null
        const loadinState = { loading: false, listReload: true }

        const officeData = {
          org_id: this.formData.org_id,
          office_type_id: this.formData.office_type_id,
          office_id: this.formData.office_id
        }
        const finalData = Object.assign(this.formData, officeData)
        if (this.formData.id) {
        result = await RestApi.putData(trainingElearningServiceBaseUrl, `${otherTrainingCalendarUpdate}/${this.$route.query.id}`, finalData)
        } else {
            result = await RestApi.postData(trainingElearningServiceBaseUrl, otherTrainingCalendarStore, finalData)
        }
        this.loading = false
        this.$store.dispatch('mutateCommonProperties', loadinState)
        if (result.success) {
          this.$toast.success({
            title: 'Success',
            message: this.id ? 'Data updated successfully' : 'Data save successfully',
            color: '#D6E09B'
          })

          this.$bvModal.hide('modal-4')
        } else {
          this.$toast.error({
            title: 'Error',
            message: result.message,
            color: '#D6E09B'
          })
          this.$refs.form.setErrors(result.errors)
        }
        this.$router.push('/training-e-learning-service/tpm/other-training-calender-list')
      },
      removeItem (index) {
        this.formData.details.splice(index, 1)
      },
      addMore () {
        const tempItem = {
            training_quarter_id: 0,
            training_type_id: 0,
            quarterly_goals: '',
              dates: [
                {
                    start_date: '',
                    end_date: '',
                    venu_name: '',
                    venu_name_bn: ''
                }
              ]
          }
        this.formData.details.push(tempItem)
      },
      dateFormation (index, value) {
        this.formData.details[index].dates = []

        for (let i = 0; i < value; i++) {
            this.formData.details[index].dates.push({
            start_date: '',
            end_date: '',
            venu_name: '',
            venu_name_bn: ''
            })
        }
      }
    }
  }
  </script>
  <style>
    #affidavit , #affidavit_bn {
      height: 150px
    }
  </style>
